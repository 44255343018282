<template>
  <div class="home">
    <v-container>
      <FideicomisoLista></FideicomisoLista>
    </v-container>
  </div>
</template>

<script>
// @ is an alias to /src
import FideicomisoLista from '@/components/FideicomisoLista.vue';
import { redireccion } from '../common/util';

export default {
  name: 'Home',
  components: {
    FideicomisoLista,
  },
  methods: {
    // redireccion() {
    //   const token = localStorage.getItem('token');
    //   if (token !== 'undefined' && token !== null && token !== '') {
    //     axios
    //       .post(`${API_URL}api/usuarios/validarToken`, {
    //         token: localStorage.getItem('token'),
    //       })
    //       .then(() => {
    //         // Token válido
    //       })
    //       .catch(() => {
    //         this.$router.push('/login');
    //       });
    //   } else {
    //     this.$router.push('/login');
    //   }
    // },
  },
  async created() {
    const isValid = await redireccion();
    if (!isValid) {
      localStorage.removeItem('token');
      localStorage.clear();
      this.$router.push('/login');
    }
  },
};
</script>
